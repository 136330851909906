import communiocationService from '../../../services/provider/recall'
import ViewModal from '../../../components/part/shared/PreviewViewModal'
import  ResponseModal from '../../share/ResponseModal'
import ErrorModal from '../../share/ErrorModal'
import proCommunicationServices from '../../../services/provider/recall'
import {
    RECALL_STATUS,
    EMAIL_STATUS,
    RECALL_STATUS_PROVIDER,
    RECALL_CLASSIFICATIONS,
    COMMUNICATION_TYPE,
    PROVIDER_EVENTLISTING_STATUS
} from '../../../misc/commons'
import { AG_GridCustomizedTableHeaders_Key, Provider_Recalls_AgGrid } from '../../../misc/tableHeaders'
import _ from 'lodash'
export default {
    components: {
        ViewModal,
        ResponseModal,
        ErrorModal
    },
    data() {
        return {
            organization_id: null,
            rowModelType: 'serverSide',
            columnDefs: null,
            gridOptions: {
                onFilterChanged: this.onFilterChanged,
                onSortChanged: this.onSortChanged,
            },
            docId: 0,
            delay: 700,
            clicks: 0,
            total: 0,
            ChildRecalltotal: 0,
            status: '',
            pageNumber: 1,
            pageSize: 1000,
            items: [],
            Originalitems: [],
            ChildRecall: [],
            listSupplier: [],
            responseDetails: null,
            respModalKey: 0,
            responseEmailKey: 0,
            viewModalKey: 0,
            errorModalKey: 0,
            title: {},
            communicationType: '',
            gridApi: null,
            columnApi: null,
            search: {
                status: null,
                category: null,
                name: null,
                supplierOrgId: null,
                supplierOrgName: null,
                site: null,
            },
            searchQuery: { pageNumber: 1, pageSize: 50, sort: null, colId: null },
            item: {},
            communicationId: null,
            IsShowSite: true,
            childHeader: [],
            disruptionHeader: [
                {
                    label: 'Complete',
                    name: 'status_provider',
                    sort: true,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center',
                    column_classes: 'customalign-2ercent',
                    row_classes: 'customalign-2percent wordbreakall',
                },
                {
                    label: 'Supplier',
                    name: 'supplier_org_name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-10percent wordbreakall table-text-ellipsis',
                },
                {
                    label: 'Advisory ID',
                    name: 'communicationUID',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-10percent wordbreakall',
                },
                {
                    label: 'Name',
                    name: 'communicationName',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-10percent wordbreakall table-text-ellipsis',
                },
                {
                    label: 'Categories',
                    name: 'categories',
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-10percent wordbreakall',
                    sort: true,
                },
                {
                    label: '# of Sites',
                    name: 'recall_sites',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Response Documents',
                    name: 'response_doc',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-5percent',
                    row_classes: 'customalign-5percent wordbreakall',
                },

                {
                    label: 'Source',
                    name: 'isProviderCreated',
                    sort: true,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-5percent',
                    row_classes: 'customalign-5percent wordbreakall',
                },

                {
                    label: 'Days Since Notified',
                    name: 'date_since_notified',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Event Status',
                    name: 'status',
                    sort: true,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center',
                    column_classes: 'customalign-5percent',
                    row_classes: 'customalign-5percent wordbreakall',
                },
                {
                    label: 'Response Status',
                    name: 'responsestatus',
                    sort: true,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center',
                    column_classes: 'customalign-5percent',
                    row_classes: 'customalign-5percent wordbreakall',
                },
                {
                    label: '',
                    name: 'action',
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center',
                },
            ],
            header: [
                {
                    label: 'Complete',
                    name: 'status_provider',
                    sort: true,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center',
                    column_classes: 'customalign-2ercent',
                    row_classes: 'customalign-2percent wordbreakall',
                },
                {
                    label: 'Supplier',
                    name: 'supplier_org_name',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-10percent wordbreakall table-text-ellipsis',
                },
                {
                    label: 'Recall ID',
                    name: 'communicationUID',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-10percent wordbreakall',
                },
                {
                    label: 'Name',
                    name: 'communicationName',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-10percent wordbreakall table-text-ellipsis',
                },
                {
                    label: 'Categories',
                    name: 'categories',
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-10percent wordbreakall',
                    sort: true,
                },
                {
                    label: '# of Sites',
                    name: 'recall_sites',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Response Documents',
                    name: 'response_doc',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-5percent',
                    row_classes: 'customalign-5percent wordbreakall',
                },
                {
                    label: 'Affected Product',
                    name: 'affectedProduct',
                    sort: true,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-10percent',
                    row_classes: 'customalign-5percent wordbreakall',
                },
                {
                    label: 'Source',
                    name: 'isProviderCreated',
                    sort: true,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-left',
                    column_classes: 'customalign-5percent',
                    row_classes: 'customalign-5percent wordbreakall',
                },

                {
                    label: 'Days Since Notified',
                    name: 'date_since_notified',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Event Status',
                    name: 'status',
                    sort: true,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center',
                    column_classes: 'customalign-5percent',
                    row_classes: 'customalign-5percent wordbreakall',
                },
                {
                    label: 'Response Status',
                    name: 'responsestatus',
                    sort: true,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center',
                    column_classes: 'customalign-5percent',
                    row_classes: 'customalign-5percent wordbreakall',
                },
                {
                    label: '',
                    name: 'action',
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center',
                },
            ],
            recall_status: _.cloneDeep(RECALL_STATUS),
            provider_eventlisting_status: _.cloneDeep(PROVIDER_EVENTLISTING_STATUS),
            recall_status_provider: RECALL_STATUS_PROVIDER,
            emailStatus: EMAIL_STATUS,
            recall_classification: RECALL_CLASSIFICATIONS,
            table_Connection_Data: this.$store.state.CustomizedHeaders.filter(s => s.userId == this.$store.state.user.id)[0],
        }
    },
    beforeMount() {
        this.columnDefs = Provider_Recalls_AgGrid
    },
    mounted() {
        var exists = this.recall_status.some(function(status) {
            return status.value === 'NA'
        })
        if (!exists) {
            this.recall_status.push({ value: 'NA', text: 'NA', color: '#ffc107' })
        }

    },
    created()
    {
        this.rowModelType = 'serverSide'
        if (this._checkPermissions(['Provider_ManageRecalls'])) {
            if (this.$store.state.lastedRouteNotAuth.fullPath != undefined) {
                return this.$router.push({ path: this.$store.state.lastedRouteNotAuth.fullPath })
            }
        }
    },
    methods: {
        saveState(params) {
            this.table_Connection_Data.Provider_Login.Recall_Headers = params.columnApi.getColumnState()
            localStorage.setItem(AG_GridCustomizedTableHeaders_Key, JSON.stringify(this.$store.state.CustomizedHeaders))
        },
        applyState(params) {
            var tableState = JSON.parse(localStorage.getItem(AG_GridCustomizedTableHeaders_Key))?.filter(s => s.userId == this.$store.state.user.id)[0]
            var columnState = tableState?.Provider_Login?.Recall_Headers
            if (columnState) {
                params.columnApi.applyColumnState({ state: columnState, applyOrder: true })
            }
        },
        onFilterChanged(event) {
            this.table_Connection_Data.Provider_Login.Recall_Filters=event.api.getFilterModel()
            localStorage.setItem(AG_GridCustomizedTableHeaders_Key, JSON.stringify(this.$store.state.CustomizedHeaders))
            this.fetchData(event)
        },
        setFilterModel(params) {
            var tableState = JSON.parse(localStorage.getItem(AG_GridCustomizedTableHeaders_Key))?.filter(s => s.userId == this.$store.state.user.id)[0]
            var filterModel = tableState?.Provider_Login?.Recall_Filters
            if (filterModel) {
                params.api.setFilterModel(filterModel)
            }
        },
        onSortChanged(event) {
            this.saveState(event)
            this.fetchData(event)
        },
        ViewClick(val,mode) {
            this.docId = val.id
            return new Promise ((resolve, reject) => {
                proCommunicationServices.getDocumentById(this.docId).then(resp => {
                    if (!resp.error) {
                        val = resp.data.d
                        let itemVal = {
                            extension: val.extension,
                            filename: val.name,
                            signed_url: val.signed_url,
                            signed_url_review: val.signed_url_review,
                            fileSize: val.size,
                            mimetype: val.type
                        }
                        if(mode == 'child')
                        {
                            this.openExternalLink(val.signed_url)
                        }
                        else
                        {
                            this.viewModalKey += 1
                            this.$nextTick(() => {
                                this.$refs.viewModal.forceRerenderInsert(itemVal)
                            })
                        }
                        resolve()
                    } else {
                        reject()
                    }
                })
            })
        },
        findProviderSummaryReport() {
            communiocationService.findProviderSummaryReport({
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                sortField: null,
                sortDirection: null,
                status: this.status,
            })
        },
        findProviderSummaryCSVReport() {
            communiocationService.findProviderSummaryCSVReport({
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                sortField: null,
                sortDirection: null,
                status: this.status,
            })
        },
        findDelegateSummaryReport() {
            communiocationService.findDelegateSummaryReport({
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                sortField: null,
                sortDirection: null,
                status: this.status,
            })
        },
        findDelgateSummaryCSVReport() {
            communiocationService.findDelgateSummaryCSVReport({
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                sortField: null,
                sortDirection: null,
                status: this.status,
            })
        },
        addMoreClick() {
            localStorage.setItem('recallMode', 'ADD')
            this.$router.push({ name: 'prov_Recall_NewItem' })
        },
        fetchData(params) {
            this.applyState(params)
            this.setFilterModel(params)
            let that = this
            that.gridApi = params.api
            that.columnApi = params.columnApi
            const datasource = {
                getRows(params) {
                    that.search.status_provider = params.request?.filterModel?.status_provider?.values
                    that.search.status = params.request?.filterModel?.status?.filter
                    that.search.responsestatus = params.request?.filterModel?.responsestatus?.filter
                    that.search.responseListingStatus = params.request?.filterModel?.responseListingStatus?.filter
                    that.search.supplier_org_name = params.request?.filterModel?.supplier_org_name?.filter
                    that.search.communicationUID = params.request?.filterModel?.communicationUID?.filter
                    that.search.communicationName = params.request?.filterModel?.communicationName?.filter
                    that.search.categories = params.request?.filterModel?.categories?.filter
                    that.search.recallSiteName = params.request?.filterModel?.recall_sites?.filter
                    that.search.source = params.request?.filterModel?.source?.values
                    that.search.date_since_notified = params.request?.filterModel?.date_since_notified?.filter
                    that.search.communicationDate = params.request?.filterModel?.communicationDate?.dateFrom?.split(' ')[0]
                    that.search.type = params.request?.filterModel?.type?.filter
                    that.search.classification = params.request?.filterModel?.classification?.filter
                    that.search.responded_at = params.request?.filterModel?.responded_at?.dateFrom?.split(' ')[0]
                    that.search.createdBy = params.request?.filterModel?.createdBy?.filter
                    that.searchQuery.colId = params.request?.sortModel[0]?.colId
                    that.searchQuery.sort = params.request?.sortModel[0]?.sort
                    that.search.filterModel = that.gridApi.getFilterModel()
                    communiocationService
                        .findRecall({
                            ...that.searchQuery,
                            sortField: params.request?.sortModel[0]?.colId,
                            sortDirection: params.request?.sortModel[0]?.sort,
                            ...that.search,
                            organizationId: that.organization_id,
                            communicationType: COMMUNICATION_TYPE.RECALL
                        }).then(resp => {
                            if (!resp.error) {
                                const recallsarray = []
                                that.Originalitems = resp.data.d.c.filter(s=>s.recallSiteId>0)
                                for (var i = 0; i < resp.data.d.c.length; i++) {
                                    if(resp.data.d.c[i].userSiteName != undefined)
                                    {
                                        if(resp.data.d.c[i].userSiteName.includes('Corporate'))
                                        {
                                            resp.data.d.c[i].isEdit = true
                                        }
                                        else
                                        {
                                            if(resp.data.d.c[i].recall_sites.includes('Corporate')){
                                                resp.data.d.c[i].isEdit = false
                                            }
                                            else
                                            {
                                                var usrarr = resp.data.d.c[i].userSiteName.split(',')
                                                var rclarr = resp.data.d.c[i].recall_sites.split(',')
                                                if(rclarr.length > usrarr.length)
                                                {
                                                    resp.data.d.c[i].isEdit = false
                                                }
                                                else
                                                {
                                                    var isEdit = true
                                                    for (var p = 0; p < rclarr.length; p++)
                                                    {
                                                        if(!resp.data.d.c[i].userSiteName.includes(rclarr[p].trimEnd().trimStart()))
                                                        {
                                                            isEdit = false
                                                        }
                                                    }
                                                    resp.data.d.c[i].isEdit = isEdit
                                                }
                                            }
                                        }
                                    }
                                    if(resp.data.d.c[i].classification === 'BLANK')
                                    {
                                        resp.data.d.c[i].classification = ''
                                    }
                                    if(resp.data.d.c[i].distributorName === 'undefined')
                                    {
                                        resp.data.d.c[i].distributorName = null
                                    }
                                    if(resp.data.d.c[i].affectedProduct === 'undefined' || resp.data.d.c[i].affectedProduct === '')
                                    {
                                        resp.data.d.c[i].affectedProduct = null
                                    }
                                    if(resp.data.d.c[i].status === null)
                                    {
                                        resp.data.d.c[i].status = ''
                                    }
                                    if(resp.data.d.c[i].responsestatus === 'BOUNCE')
                                    {
                                        resp.data.d.c[i].responsestatus = 'ERROR'
                                    }
                                    if(resp.data.d.c[i].responseListingStatus === null)
                                    {
                                        resp.data.d.c[i].responseListingStatus = ''
                                    }
                                    const TotalCopyCount=resp.data.d.c.filter(s=>s.communicationId==resp.data.d.c[i].communicationId && s.recallSiteId>0).length
                                    resp.data.d.c[i].recallSiteCount=TotalCopyCount
                                    var checkexist=recallsarray.filter(s=>s.communicationId==resp.data.d.c[i].communicationId)
                                    if (checkexist.length==0) {
                                        const CompletedCount=resp.data.d.c.filter(s=>s.communicationId==resp.data.d.c[i].communicationId && s.recallSiteId>0 && s.status_provider=='COMPLETED').length
                                        if(TotalCopyCount !=0 && TotalCopyCount!=CompletedCount)
                                        {
                                            resp.data.d.c[i].status_provider='NOT COMPLETED'
                                        }
                                        if(TotalCopyCount !=0 && TotalCopyCount==CompletedCount){
                                            resp.data.d.c[i].status_provider='COMPLETED'
                                        }

                                        recallsarray.push(resp.data.d.c[i])
                                    }
                                }
                                that.item = resp.data.d.c
                                that.total = resp.data.d.t
                                if(that.total == 0){
                                    that.gridApi.showNoRowsOverlay()
                                }
                                else{
                                    that.gridApi.hideOverlay()
                                }
                                return recallsarray
                            }
                        }).then(response => {
                            params.successCallback(response)
                        })
                        .catch(error => {
                            console.error(error)
                            params.failCallback()
                        })
                }
            }
            // register datasource with the grid
            params.api.setServerSideDatasource(datasource)
        },
        viewChildRecall(item)
        {
            this.$router.push({
                name: item.communicationType == COMMUNICATION_TYPE.RECALL ?  'prov_RecallView' : 'prov_DisruptionView',
                params: { id: item.communicationId ,providerCommunicationId: item.providerCommunicationId,recallSiteCount: item.recallSiteCount, switchSitesList: this.ChildRecall},
            })
        },
        onCellClicked(params) {
            if (params.colDef.field == 'responsestatus') {
                this.errorModel(params.data,'btn')
                return
            }
            if (params.colDef.field == 'allresponse_doc') {
                if(params.event.target.dataset.action != undefined){
                    if(params.event.target.dataset.action.includes('viewclick'))
                    {
                        for(let i=0; i <= params.data.allresponse_doc.length; i++)
                        {
                            if (params.event.target.dataset.action === 'viewclick_' + params.data.allresponse_doc[i].id) {
                                return this.ViewClick(params.data.allresponse_doc[i],'parent')
                            }
                        }
                    }
                }
            }
            if (params.column.colId === 'actions') {
                if (params.event.target.dataset.action === 'view') {
                    this.viewRecall(params.data)
                }
                if (params.event.target.dataset.action === 'edit') {
                    this.editRecall(params.data)
                }
                return
            }else{
                this.viewRecall(params.data)
            }
        },
        errorModel(val) {
            if(val.responsestatus == this.emailStatus.ERROR && val.isProviderCreated)
            {
                let data = []
                data.bounce_type = val.bounceType
                data.bounce_sub_type = val.bounceSubType
                data.description = val.description
                this.errorModalKey += 1
                this.$nextTick(() => {
                    this.$refs.errorModal.forceRerenderView(
                        data
                    )
                })
            }
        },
        viewRecall(item) {
            var Childrecall= this.Originalitems.filter(s=>s.communicationId==item.communicationId && s.recallSiteId>0 && s.recallSiteCount>1)
            if(Childrecall.length>0)
            {
                this.ChildRecall=Childrecall
                this.ChildRecalltotal= Childrecall.length
                this.childHeader = item.communicationType == COMMUNICATION_TYPE.PRODUCTDISRUPTION ? this.disruptionHeader : this.header
                this.title = {
                    type: item.communicationType == COMMUNICATION_TYPE.RECALL ? 'Recall' : 'Supply Advisory',
                }
                this.$root.$emit('bv::show::modal', 'modal-xl', item.communicationType)
            }
            else{
                this.$router.push({
                    name: item.communicationType == COMMUNICATION_TYPE.RECALL ?  'prov_RecallView' : 'prov_DisruptionView',
                    params: { id: item.communicationId, providerCommunicationId: item.providerCommunicationId,recallSiteCount: item.recallSiteCount},
                })
            }
        },
        async download(val) {
            this.ViewClick(val,'child')
        },
        editRecall(item, e) {
            localStorage.setItem('recallMode', 'EDIT')
            this.$router.push({
                name: 'prov_Recall_EditItem',
                params: { id: item.communicationId,providerCommunicationId: item.providerCommunicationId },
            })
            e.stopPropagation()
        },
    },
}
